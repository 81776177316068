import React, { createContext, useContext } from "react";
import {
	mapResponsiveValue,
	Responsive,
	ResponsiveStyles,
	useResponsiveStyles,
} from "../utils/useResponsiveStyles";
import { ColorName, TextSize, TextWeight } from "./theme";
import { useTheme } from "./ThemeProvider";

type TextStylesContextValue = Pick<
	TextProps,
	| "size"
	| "color"
	| "weight"
	| "align"
	| "italic"
	| "balance"
	| "fancy"
	| "truncate"
>;

const TextStylesContext = createContext<TextStylesContextValue>({
	size: "md",
	color: "sand12",
	weight: "normal",
});

TextStylesContext.displayName = "TextStylesContext";

export const TextStylesProvider: React.FC<
	TextStylesContextValue & { children: React.ReactNode }
> = ({ children, ...props }) => {
	const ancestorStyles = useContext(TextStylesContext);

	return (
		<TextStylesContext.Provider value={{ ...ancestorStyles, ...props }}>
			{children}
		</TextStylesContext.Provider>
	);
};

type TextProps = {
	as?: "span" | "p" | "label";
	size?: Responsive<TextSize>;
	style?: ResponsiveStyles;
	color?: ColorName;
	light?: boolean;
	weight?: Responsive<TextWeight>;
	align?: "left" | "center" | "right";
	italic?: boolean;
	balance?: Responsive<boolean>;
	// Use a serif font to look schmancy
	fancy?: boolean;
	truncate?: boolean;
	children?: string | (string | React.ReactNode)[];
};

export const Text: React.FC<TextProps> = ({
	as: elementName = "span",
	truncate = false,
	children,
	light,
	color: propColor = light ? "sand11" : undefined,
	...propStyles
}) => {
	const theme = useTheme();
	const ancestorStyles = useContext(TextStylesContext);

	const {
		size,
		style,
		weight,
		color: colorName,
		align,
		balance,
		italic,
		fancy,
	}: TextProps = {
		...ancestorStyles,
		...propStyles,
		...(propColor ? { color: propColor } : {}),
	};

	const fontStyle = italic ? "italic" : undefined;

	const fontStyles = theme.fontStyles;

	const textOverflow = truncate ? "ellipsis" : undefined;
	const whiteSpace = truncate ? "nowrap" : undefined;
	const overflow = truncate ? "hidden" : undefined;

	const className = useResponsiveStyles({
		fontSize: mapResponsiveValue(size, (size) =>
			size ? fontStyles[size].fontSize : undefined,
		),
		letterSpacing: mapResponsiveValue(size, (size) =>
			size ? fontStyles[size].letterSpacing : undefined,
		),
		lineHeight: mapResponsiveValue(size, (size) =>
			size ? fontStyles[size].lineHeight : undefined,
		),
		fontFamily: mapResponsiveValue(fancy, (fancy) =>
			fancy ? theme.fontFamilies.Newsreader : undefined,
		),
		fontWeight: mapResponsiveValue(weight, (weight) =>
			weight ? theme.textWeights[weight] : undefined,
		),
		textWrap: mapResponsiveValue(balance, (balance) =>
			balance ? "balance" : undefined,
		),
		color: colorName ? theme.colors[colorName] : undefined,
		textAlign: align,
		fontStyle,
		textOverflow,
		whiteSpace,
		overflow,
		margin: 0,
		...style,
	});

	return React.createElement(
		elementName,
		{ className },
		typeof children === "string" ? (
			children
		) : (
			<TextStylesProvider {...propStyles}>{children}</TextStylesProvider>
		),
	);
};
