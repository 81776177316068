import React, { createContext } from "react";
import { useRequiredContext } from "../utils/useRequiredContext";
import { darkTheme, lightTheme, Theme } from "./theme";

const ThemeContext = createContext<Theme | undefined>(undefined);

export function useTheme(): Theme {
	const theme = useRequiredContext(ThemeContext);
	return theme;
}

type ThemeProviderProps = {
	children: React.ReactNode;
	mode: "light" | "dark";
};

export function ThemeProvider({ children, mode }: ThemeProviderProps) {
	const theme = mode === "light" ? lightTheme : darkTheme;

	return (
		<ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
	);
}
