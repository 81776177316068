import {
	blackA,
	sand,
	sandA,
	sandDark,
	violet,
	whiteA,
} from "@radix-ui/colors";
import { range } from "lodash-es";
import React, { useEffect } from "react";
import IOSAppIcon from "./logos/iOSAppIcon.svg";
import Logo from "./logos/Logo.svg";
import CircleLogo from "./logos/LogoCircle.svg";
import MessageTail from "./logos/MessageTail.svg";

import { PurseProvider, useStyles } from "purse-styles";
import { useState } from "react";
import { Icon, IconName } from "./Icon";
import "./style.css"; // Updated to include new styling
import { Tooltip } from "./Tooltip";
import { Box, Spacer } from "./ui/Box";
import { Text } from "./ui/Text";
import { ColorName } from "./ui/theme";
import { ThemeProvider, useTheme } from "./ui/ThemeProvider";
import {
	mapResponsiveValue,
	Responsive,
	useResponsiveStyles,
} from "./utils/useResponsiveStyles";

function useMediaQuery(query: string) {
	const [matches, setMatches] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia(query);
		setMatches(mediaQuery.matches);

		const handleMatchChange = (e: MediaQueryListEvent) => {
			setMatches(e.matches);
		};

		mediaQuery.addEventListener("change", handleMatchChange);
		return () => {
			mediaQuery.removeEventListener("change", handleMatchChange);
		};
	}, [query]);

	return matches;
}

type HeroTitleProps = {
	children: string;
};

const HeroTitle: React.FC<HeroTitleProps> = ({ children }) => {
	return (
		<Text
			weight={"bold"}
			align="center"
			balance
			style={{
				maxWidth: 800,
				fontSize: {
					initial: 66,
					"<md": 48,
				},
				lineHeight: {
					initial: "64px",
					"<md": "44px",
				},
				letterSpacing: {
					initial: "-0.03em",
					"<md": "-0.02em",
				},
			}}
		>
			{children}
		</Text>
	);
};

type HeroSubtitleProps = {
	children: string | (string | React.ReactNode)[];
};

const HeroSubtitle: React.FC<HeroSubtitleProps> = ({ children }) => {
	return (
		<Text
			size={{ initial: "xl", "<md": "lg" }}
			align="center"
			style={{ maxWidth: 680 }}
			balance
		>
			{children}
		</Text>
	);
};

type CallOutSectionTextProps = {
	children: string | (string | React.ReactNode)[];
	fancy?: boolean;
};

const CallOutSectionText: React.FC<CallOutSectionTextProps> = ({
	children,
	fancy,
}) => {
	return (
		<Text
			italic={fancy}
			fancy={fancy}
			color="sand12"
			style={{
				maxWidth: 560,
				fontSize: {
					initial: 40,
					"<md": 24,
				},
				lineHeight: {
					initial: "42px",
					"<md": "28px",
				},
				letterSpacing: {
					initial: "-0.005em",
					"<md": 0,
				},
				fontWeight: 500,
			}}
		>
			{children}
		</Text>
	);
};

type SectionSubtitleProps = {
	children: string | (string | React.ReactNode)[];
};

const SectionSubtitle: React.FC<SectionSubtitleProps> = ({ children }) => {
	return (
		<Text
			as="p"
			align="center"
			light
			size="md"
			balance
			style={{ maxWidth: 800 }}
		>
			{children}
		</Text>
	);
};

type SectionTitleProps = {
	children: string;
};

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => {
	return (
		<Text
			as="p"
			size="xxxl"
			weight="semibold"
			align="center"
			balance
			style={{ maxWidth: 640 }}
		>
			{children}
		</Text>
	);
};

// Editing comment to merge to prod
export function Website() {
	const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

	return (
		<ThemeProvider mode="light">
			<PurseProvider>
				<Box
					className={isDarkMode ? "dark" : "light"}
					column
					background="sand1"
					style={{ color: sand.sand12 }}
				>
					<Box
						column
						align="stretch"
						style={{ position: "relative", zIndex: 2 }}
					>
						{/* <OpenGraphImage /> */}
						<HeaderSection />
						<CurrentToolsAreFailingCard />
						{/* <AverageTimeCard /> */}
						{/* <Spacer height={80} /> */}
						{/* <CallOutHeaderCard /> */}
						<CalloutForIndividuals />
						{/* <SimplifyCarePlansSection />
						<PersonalizeCarePlansSection /> */}
						<SimplifyPersonalizeAutomate />
						{/* <RightInformationSection />
						<PersonalResearchAssistantSection />
						<SchedulingSection /> */}
						<Divider />
						<Footer />
					</Box>
				</Box>
			</PurseProvider>
		</ThemeProvider>
	);
}

const iPhoneDeviceBackground = new URL(
	"../media/iPhone_frame.png",
	import.meta.url
);

// Use for generating open graph  images
const OpenGraphImage: React.FC = () => {
	const theme = useTheme();
	return (
		<Box
			column
			align="center"
			justify="center"
			style={{
				position: "relative",
				overflow: "hidden",
				width: "100vw",
				height: "100vh",
			}}
		>
			<div
				style={{
					position: "absolute",
					inset: 0,
					zIndex: -1,
				}}
			>
				<div
					className="og-gradient"
					style={{ position: "absolute", inset: 0 }}
				/>
				<div
					style={{
						position: "absolute",
						inset: 0,
						transform: `translateY(50%)`,
					}}
				>
					<PolarCoordinateGrid
						initialR={80}
						circleCount={3}
						rStep={120}
						color={sandA.sandA3}
					/>
				</div>
			</div>
			<Box padding="lg" flex>
				<Box column align="center" flex>
					<Box gap="xxs" align="center">
						<Logo width={32} height={32} color={theme.colors.sand12} />
						<Text size="xxxl" weight="semibold" color="sand12">
							Saffron
						</Text>
					</Box>
					<Spacer style={{ height: "12%" }} />
					<HeroTitle>
						Chronic care software that works for the patient
					</HeroTitle>
					<Spacer flex />
				</Box>
			</Box>
			<Box
				column
				align="center"
				style={{
					position: "absolute",
					left: 0,
					right: 0,
					bottom: -200,
				}}
			>
				<Box style={{ height: "85vh", position: "relative" }}>
					<img
						src={AppScreenshotImage.toString()}
						style={{
							width: "auto",
							height: "100%",
							objectFit: "contain",
							objectPosition: "center",
							aspectRatio: "774 / 1586",
						}}
						alt="Saffron app screenshot"
					/>
					<Box shadow="xl" style={{ position: "absolute", inset: 10 }} />
				</Box>
			</Box>
		</Box>
	);
};

const HeaderSection: React.FC = () => {
	return (
		<>
			<Box
				column
				align="stretch"
				style={{ overflow: "hidden", position: "relative" }}
			>
				<div
					style={{
						position: "absolute",
						inset: 0,
						zIndex: -1,
					}}
				>
					<div
						className="hero-gradient"
						style={{ position: "absolute", inset: 0 }}
					/>
					<div
						style={{
							position: "absolute",
							inset: 0,
							transform: `translateY(-50%)`,
						}}
					>
						<PolarCoordinateGrid
							initialR={80}
							circleCount={3}
							rStep={120}
							color={sandA.sandA3}
						/>
					</div>
				</div>
				<Spacer style={{ height: 24 }} />
				<Box gap="xxs" paddingHorizontal="lg" align="center">
					<Logo width={24} height={24} />
					<Text size={"lg"} weight={"semibold"} color="sand12">
						Saffron
					</Text>
				</Box>
				<Spacer
					style={{
						height: {
							initial: 96,
							"<md": 64,
						},
					}}
				/>
				<Box column align="center" paddingHorizontal="lg">
					<HeroTitle>
						Chronic care software that works for the patient
					</HeroTitle>
				</Box>
				<Spacer style={{ height: 32 }} />
				<Box column align="center" paddingHorizontal="md">
					<HeroSubtitle>
						Saffron is a personal assistant for patients with chronic conditions
						to{" "}
						<Icon
							name="Hospital"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(3px)` }}
						/>{" "}
						navigate complex care plans,{" "}
						<Icon
							name="SmartphoneNfc"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(3px)` }}
						/>{" "}
						keep providers informed outside appointments, and{" "}
						<Icon
							name="Workflow"
							size={{ initial: 20, "<md": 18 }}
							color="accent11"
							style={{ transform: `translateY(3px)` }}
						/>{" "}
						automate time-consuming logistical tasks
					</HeroSubtitle>
				</Box>
				<Spacer style={{ height: 32 }} />
				<Box column align="center">
					<ContactUsButton />
				</Box>
				<Spacer style={{ height: 36 }} />
				<Text align="center" fancy italic size={"xl"}>
					Watch the demo
				</Text>
				<Spacer style={{ height: 8 }} />
				<Box
					column
					align="center"
					paddingHorizontal="lg"
					style={{ width: "100%", zIndex: 3 }}
				>
					<Box align="center" justify="center" style={{ position: "relative" }}>
						<Box style={{ border: `12px solid transparent` }}>
							<video
								style={{
									height: 540,
									width: "auto",
									zIndex: 1,
									borderRadius: 34,
								}}
								controls
							>
								<source
									src="https://static.saffron.health/saffron_demo.mp4"
									type="video/mp4"
								/>
							</video>
						</Box>
						<img
							src={iPhoneDeviceBackground.toString()}
							style={{
								position: "absolute",
								pointerEvents: "none",
								top: "50% ",
								left: "50%",
								transform: `translate(-50%, -50%) scale(1.044)`,
								aspectRatio: "434 / 882",
								height: 540,
								width: "auto",
								objectFit: "contain",
								objectPosition: "center",
								zIndex: 2,
							}}
						/>
					</Box>
				</Box>
				<Spacer style={{ height: { initial: 120, "<md": 80 } }} />
				{/* <CalloutForIndividuals /> */}
			</Box>
		</>
	);
};

type StatCardProps = {
	stat: string;
	unit: string;
	description: string;
	tooltipContent: string;
	tooltipSource: string;
	tooltipLink: string;
};

const StatCard: React.FC<StatCardProps> = ({
	stat,
	unit,
	description,
	tooltipContent,
	tooltipSource,
	tooltipLink,
}) => {
	return (
		<Box
			column
			align="center"
			paddingVertical="md"
			paddingHorizontal="md"
			gap="sm"
		>
			<Box align="flex-end" gap="xxxs">
				<Text
					style={{
						fontSize: {
							initial: 84,
							"<md": 64,
						},
						lineHeight: {
							initial: "84px",
							"<md": "64px",
						},
					}}
					color="accent9"
					weight={"medium"}
				>
					{stat}
				</Text>
				<Text color="accent9" weight={"semibold"} size={"lg"}>
					{unit}
				</Text>
			</Box>
			<Text light size={"md"} balance align="center" style={{ maxWidth: 220 }}>
				{description}
				{tooltipContent && tooltipSource && (
					<Tooltip
						content={tooltipContent}
						source={tooltipSource}
						url={tooltipLink}
					/>
				)}
			</Text>
		</Box>
	);
};

const _AverageTimeCard: React.FC = () => {
	return (
		<ThemeProvider mode="dark">
			<Box
				column
				align="center"
				style={{
					position: "relative",
					padding: {
						initial: 60,
						"<md": 40,
					},
					zIndex: 2,
				}}
			>
				<Box
					background="sand2"
					className="grid-background"
					borderTopWidth={"md"}
					borderBottomWidth={"md"}
					borderColor="sand6"
					style={{
						position: "absolute",
						overflow: "hidden",
						inset: "-80px 0",
						transform: `skewY(-2.7deg)`,
						zIndex: -1,
						boxShadow: `0 0 10px 2px inset ${sandDark.sand1}`,
					}}
				/>
				<Box column align="center" paddingVertical="md" paddingHorizontal="md">
					<SectionTitle>
						Today, people are forced to give up their own aspirations to support
						their family’s health
					</SectionTitle>
					<Spacer style={{ height: 48 }} />
					<Box
						gap={{ initial: "lg", "<md": "md" }}
						wrap="wrap"
						justify="center"
					>
						<StatCard
							stat="21.3"
							unit="%"
							description="of adults have intensive caregiving duties "
							tooltipContent="Intensive caregiving duties include caring for an adult or a child with special needs."
							tooltipSource="AARP and National Alliance for Caregiving. Caregiving in the United States 2020. Washington, DC: AARP. May 2020."
							tooltipLink="https://doi.org/10.26419/ppi.00103.001"
						/>
						<StatCard
							stat="24"
							unit="hrs / wk"
							description="are spent on caregiving "
							tooltipContent="On average, caregivers in the U.S. spend 24 hours per week providing care."
							tooltipSource="AARP and National Alliance for Caregiving. Caregiving in the United States 2020. Washington, DC: AARP. May 2020."
							tooltipLink="https://doi.org/10.26419/ppi.00103.001"
						/>
						<StatCard
							stat="61"
							unit="%"
							description="of caregivers experience career setbacks "
							tooltipContent="Setbacks include reducing hours, taking a leave of absence, receiving performance warnings, declining promotions, quitting, and losing job benefits."
							tooltipSource="AARP and National Alliance for Caregiving. Caregiving in the United States 2020. Washington, DC: AARP. May 2020."
							tooltipLink="https://doi.org/10.26419/ppi.00103.001"
						/>
					</Box>
					<Spacer style={{ height: 48 }} />
					<Text
						size={{ initial: "xl", "<md": "lg" }}
						weight={"medium"}
						color="sand12"
						align="center"
						balance
						style={{ maxWidth: 520 }}
					>
						You shouldn't have to sacrifice your goals to care for your family.
					</Text>
					<Text
						color="accent10"
						size={{ initial: "xl", "<md": "lg" }}
						weight={"medium"}
						align="center"
						balance
					>
						Help should be available when you need it.
						{/* The support you need should be right at your fingertips. */}
					</Text>

					<Spacer style={{ height: 32 }} />
				</Box>
			</Box>
		</ThemeProvider>
	);
};

const CurrentToolsAreFailingCard: React.FC = () => {
	return (
		<ThemeProvider mode="dark">
			<Box
				column
				align="center"
				style={{
					position: "relative",
					paddingBottom: { initial: 64, "<md": 48 },
					zIndex: 2,
				}}
			>
				<Box
					className="grid-background-backward"
					borderTopWidth={"md"}
					borderBottomWidth={"md"}
					shadow={"xl"}
					borderColor="sand6"
					background="sand1"
					style={{
						position: "absolute",
						overflow: "hidden",
						inset: "-80px 0",
						transform: `skewY(2.7deg)`,
						zIndex: -1,
					}}
				/>
				<Box column align="center" paddingVertical="md" paddingHorizontal="md">
					<Text
						size={{ initial: "xxxl", "<md": "xxl" }}
						weight="semibold"
						align="center"
						balance
						style={{ maxWidth: 520 }}
					>
						Patients with chronic conditions struggle to adhere to their care
						plans
					</Text>
					<Spacer style={{ height: 48 }} />

					<Box gap="lg" wrap justify="center" align="stretch">
						<Card
							icon="CircleHelp"
							title="Confusing plan details"
							description="Important information is buried in outdated patient portals and lengthy documents, leading to confusion and increased questions for providers"
						/>
						<Card
							icon="EyeOff"
							title="Limited visibility"
							description="Providers lack insight into patient actions after appointments, making it hard to know if care plans are being followed or understood"
						/>
					</Box>
					<Spacer height="lg" />
					<Box gap="lg" wrap justify="center" align="stretch">
						<Card
							icon="FileClock"
							title="Rigid care plans"
							description="Creating personalized care plans is time-consuming, and more generic plans often fail to align with patient goals and require frequent adjustment"
						/>
						<Card
							icon="Construction"
							title="Logistical barriers"
							description="Scheduling conflicts, insurance issues, and other logistical challenges delay care and make adherence more difficult for patients"
						/>
					</Box>
				</Box>
			</Box>
			<Spacer style={{ height: 100 }} />
		</ThemeProvider>
	);
};

type CardProps = {
	icon: IconName;
	title: string;
	description: string;
};

const Card: React.FC<CardProps> = ({ icon, title, description }) => {
	return (
		<Box
			column
			paddingHorizontal="md"
			paddingVertical="md"
			borderWidth={"md"}
			borderRadius={"md"}
			shadow={"md"}
			basis={320}
			gap="xs"
			background="sand4"
			style={{ minHeight: 140 }}
		>
			<Box align="center" gap="xs">
				<Icon name={icon} size={18} color="sand12" />
				<Text weight={"semibold"} color="sand12">
					{title}
				</Text>
			</Box>
			<Text as="p" light>
				{description}
			</Text>
		</Box>
	);
};

const _CallOutHeaderCard: React.FC = () => (
	<div style={{ position: "relative" }}>
		<div
			className="call-out-section-gradient"
			style={{ position: "absolute", inset: "0 0 -320px 0", zIndex: -1 }}
		/>
		<Box
			column
			paddingHorizontal="md"
			style={{
				paddingBottom: { "<md": 160, initial: 240 },
				zIndex: 10,
			}}
			align="center"
		>
			<Box
				column
				align="center"
				gap="md"
				paddingHorizontal="xl"
				paddingVertical="xl"
				borderRadius={"xl"}
				borderColor="accent8"
				background="sand2"
				borderWidth={"lg"}
				shadow={"xl"}
				style={{
					zIndex: 8,
					maxWidth: `min(480px , 100%)`,
				}}
			>
				<SectionTitle>
					Saffron approaches care management differently
				</SectionTitle>
				<SectionSubtitle>
					<Icon
						name="Database"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(2px)` }}
					/>{" "}
					Detailed health data, an{" "}
					<Icon
						name="BotMessageSquare"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(3px)` }}
					/>{" "}
					AI research assistant, and{" "}
					<Icon
						name="Workflow"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(3px)` }}
					/>{" "}
					complex task automation, for a{" "}
					<Icon
						name="Percent"
						size={{ initial: 18, "<md": 16 }}
						color="accent11"
						style={{ transform: `translateY(3px)` }}
					/>{" "}
					fraction of the cost
				</SectionSubtitle>
			</Box>
		</Box>
	</div>
);

// Colors with icons
const CalloutForIndividuals: React.FC = () => {
	const imageMaxHeightStyle = useResponsiveStyles({
		maxHeight: {
			initial: "80vh",
			"<md": "50vh",
		},
	});

	return (
		<div>
			<Box
				column={{
					initial: false,
					"<lg": true,
				}}
				paddingVertical="lg"
				paddingHorizontal="sm"
				align="center"
				justify="center"
				gap="xxxxl"
				style={{ maxWidth: 960, margin: "auto" }}
			>
				<Box align="center">
					<img
						src={AppScreenshotImage.toString()}
						className={imageMaxHeightStyle}
						style={{
							width: "auto",
							height: "100%",
							maxHeight: "65vh",
							objectFit: "contain",
							aspectRatio: "774 / 1586",
						}}
						alt="Saffron app screenshot"
					/>
				</Box>
				<Box
					column
					align="center"
					paddingVertical="lg"
					paddingHorizontal={{ initial: undefined, "<md": "lg" }}
				>
					<CallOutSectionText>
						Saffron{" "}
						<FeatureBadge
							icon="BookUser"
							text="simplifies management"
							color="blue9"
						/>
						,
						<FeatureBadge
							icon="CircleUser"
							text="personalizes care"
							color="accent9"
						/>
						, and{" "}
						<FeatureBadge
							icon="Headset"
							text="automates logistical work"
							color="ruby9"
						/>{" "}
						so providers save time and patients can focus on getting healthy
					</CallOutSectionText>
				</Box>
			</Box>
			<Spacer
				style={{
					height: {
						initial: 120,
						"<md": 48,
					},
				}}
			/>
		</div>
	);
};

type FeatureBadgeProps = {
	icon: IconName;
	text: string;
	color: ColorName;
};

const FeatureBadge: React.FC<FeatureBadgeProps> = ({ icon, text, color }) => {
	return (
		<Box
			inline
			gap="xxs"
			align="baseline"
			background={color}
			borderRadius="full"
			style={{
				padding: { initial: "4px 16px 4px 10px", "<md": "0px 12px 2px 8px" },
				margin: "4px 0",
				whiteSpace: "nowrap",
			}}
		>
			<ThemeProvider mode="dark">
				<Icon
					name={icon}
					color="sand12"
					size={{ initial: 32, "<md": 20 }}
					style={{ transform: `translateY(3px)` }}
				/>{" "}
				<Text weight="normal">{text}</Text>
			</ThemeProvider>
		</Box>
	);
};

type DividerProps = {
	vertical?: Responsive<boolean>;
	className?: string;
	size?: number;
};

const Divider: React.FC<DividerProps> = ({ vertical, size = 180 }) => {
	const height = mapResponsiveValue(vertical, (vertical): number =>
		vertical ? size : 0
	);
	const width = mapResponsiveValue(vertical, (vertical): number =>
		vertical ? 0 : size
	);

	const className = useResponsiveStyles({ height, width });

	return (
		<Box
			className={className}
			borderLeftWidth={mapResponsiveValue(vertical, (vertical) =>
				vertical ? "md" : undefined
			)}
			borderTopWidth={mapResponsiveValue(vertical, (vertical) =>
				vertical ? undefined : "md"
			)}
			borderColor="sand6"
			alignSelf="center"
		/>
	);
};

const SimplifyPersonalizeAutomate: React.FC = () => {
	return (
		<Box
			column
			align="center"
			style={{
				position: "relative",
				paddingBottom: { initial: 64, "<md": 48 },
				zIndex: 2,
			}}
		>
			{/* <Box
				className="section-grid-background-yellow"
				borderTopWidth={"md"}
				borderBottomWidth={"md"}
				shadow={"xl"}
				borderColor="sand4"
				background="sand4"
				style={{
					position: "absolute",
					overflow: "hidden",
					inset: "-10px 0",
					transform: `skewY(0deg)`,
					zIndex: -1,
					width: "100%", // Ensure the background stretches to fill the width
				}}
			/> */}
			<SimplifyCarePlansSection />
			<PersonalizeCarePlansSection />
			<AutomateCarePlansSection />
		</Box>
	);
};

type NotificationBoxProps = {
	title: string;
	description: string;
};

const NotificationBox: React.FC<NotificationBoxProps> = ({
	title,
	description,
}) => {
	return (
		<Box
			gap="sm"
			align="center"
			paddingHorizontal="sm"
			paddingVertical="sm"
			background="sandA2"
			borderRadius="lg"
			style={{
				backdropFilter: "blur(10px)",
				position: "relative",
				maxWidth: 440,
			}}
		>
			<Box>
				<IOSAppIcon fill="#6E56CF" width={38} height={38} />
			</Box>
			<Box column>
				<Box justify="space-between">
					<Text weight="semibold">{title}</Text>
					<Text light size="sm">
						now
					</Text>
				</Box>
				<Text>{description}</Text>
			</Box>
		</Box>
	);
};

const SimplifyCarePlansSection: React.FC = () => {
	return (
		<Section
			title="Simplify how you manage your patients' care"
			description="Saffron automatically extracts key information from medical records and handles routine patient communication, reducing administrative work for providers while improving adherence."
		>
			<ConversationExamples
				conversation1Type="notification"
				conversation1={[
					user("Log measurement"),
					user("Good morning Michael, what's your current blood pressure?"),
				]}
				caption1="Automatically convert doctor notes into recurring patient reminders"
				conversation2={[
					user("What medications was I prescribed from my last visit?"),
					assistant([
						assistantAccessingMedicalRecords(),
						assistantText(
							"You were prescribed Amlodipine at a dosage of 5 mg of once per day."
						),
					]),
				]}
				caption2="Immediately answer patient questions from trusted source documentation"
				// conversationBubbleColor="amber$1"
				conversationBubbleColor="sand2"
			/>
		</Section>
	);
};

const PersonalizeCarePlansSection: React.FC = () => (
	<Section
		title="Give your patients the personalized care they deserve"
		description="Saffron helps patients track health data and symptoms, then intelligently summarizes the most relevant information for providers."
	>
		<ConversationExamples
			conversation1={[
				user(
					"I took my medications like usual today, but I have a lot more brain fog than normal. Maybe it's because I didn't sleep as well last night"
				),
				assistant([
					assistantSavingPatientLog(),
					assistantText(
						"You also reported feeling more brain fog last week. Had you felt this way before starting the Lexapro 2 weeks ago?"
					),
				]),
			]}
			caption1="Provide patients with insights from health logs"
			conversation2Type="notification"
			conversation2={[
				user("Pushed data"),
				user("Sent doctor summary of latest blood pressure measurements"),
			]}
			caption2="Condensed and actionable patient data is pushed to providers"
			conversationBubbleColor="amber1"
		/>
	</Section>
);

const AutomateCarePlansSection: React.FC = () => (
	<Section
		title="Help your patients overcome logistical barriers to care"
		description="From appointment scheduling to insurance claims, Saffron handles the administrative burden so patients can focus on their health."
	>
		<ConversationExamples
			conversation1={[
				user(
					"Call my insurance to ask why they denied the claim for my last appointment with Dr. Seward."
				),
				assistant([
					assistantText(
						"Calling Cigna to check on the denial status of your claim."
					),
					assistantCallInProgress(),
				]),
			]}
			caption1="Assist patients with resolving insurance claim disputes"
			conversation2={[
				user("Schedule an appointment next week with Dr. Khosla"),
				assistant([
					assistantText(
						"Calling Dr. Khosla's office to check availability and make an appointment."
					),
					assistantCallCompleted(),
					assistantText(
						"Your appointment with Dr. Khosla is scheduled for next Monday at 2:30pm."
					),
				]),
			]}
			caption2="Seamless appointment coordination with specialists"
			conversationBubbleColor="amber1"
		/>
	</Section>
);

const _RightInformationSection: React.FC = () => (
	<Section
		title="The best care starts with the right information"
		description="Access the latest information from trusted sources to help you find top
			doctors and clinics, personalized for you and your family's needs."
	>
		<RightInformationDiagram />
	</Section>
);

const DrChoudharyImage = new URL(
	"../media/DrChoudhary.png?width=100",
	import.meta.url
);
const AppScreenshotImage = new URL(
	"../media/SaffronExample.png",
	import.meta.url
);

type InfoSourceBadge = {
	icon: IconName;
	text: string;
	top: number;
	left: number;
};

const badges: InfoSourceBadge[] = [
	{
		icon: "FileUser",
		text: "Personal health history",
		top: 24.5,
		left: 20,
	},
	{
		icon: "Hospital",
		text: "EHR systems",
		top: 14.4,
		left: 50.2,
	},
	{
		icon: "BookMarked",
		text: "Provider directories",
		top: 26,
		left: 80,
	},
	{
		icon: "TestTube",
		text: "Clinical research",
		top: 44.7,
		left: 85.9,
	},
	{
		icon: "Globe",
		text: "Online reviews and forums",
		top: 62.5,
		left: 82,
	},
	{
		icon: "Capital",
		text: "Government programs",
		top: 60.8,
		left: 20.3,
	},
	{
		icon: "FileText",
		text: "Insurance claims",
		top: 42.9,
		left: 14,
	},
];

const RightInformationDiagram: React.FC = () => {
	const theme = useTheme();
	const mobileOnlyStyle = useResponsiveStyles({
		display: { initial: "none", "<md": "flex" },
	});
	const desktopOnlyStyle = useResponsiveStyles({
		display: { initial: "flex", "<md": "none" },
	});

	return (
		<Box column align="center" style={{ width: `clamp(320px,100%,640px)` }}>
			<Box
				wrap
				justify="center"
				gap="xs"
				className={mobileOnlyStyle}
				style={{ marginBottom: -60, zIndex: 5 }}
			>
				{badges.map((badge, index) => (
					<Badge key={index} icon={badge.icon} text={badge.text} />
				))}
			</Box>
			<div
				style={{
					position: "relative",
					width: "100%",
					aspectRatio: "16 / 11",
				}}
			>
				<div style={{ position: "absolute", inset: 0 }}>
					<PolarCoordinateGrid
						initialR={50}
						rStep={50}
						circleCount={4}
						color={sand.sand6}
					/>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 640 440"
						fill="none"
						style={{ position: "absolute", inset: 0 }}
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M320.285 117.742V178.407"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M399.868 166.014L358.119 195.368"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
						<path
							d="M409 222.112H385.517"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M273.318 158.185L285.06 175.145"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
						<path
							d="M212 177.755L278.536 205.805"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M285.06 291.258L298.106 261.252"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
						<path
							d="M235.484 261.251L273.318 241.682"
							stroke={violet.violet9}
							strokeWidth={1.5}
						/>
						<path
							d="M350.291 239.073L393.344 270.384"
							stroke={violet.violet6}
							strokeWidth={1.5}
						/>
					</svg>
				</div>
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: `translate(-50%, -50%)`,
					}}
				>
					<CircleLogo
						width={48}
						height={48}
						style={{
							"--background": theme.colors.accent9,
							"--foreground": theme.colors.sand1,
						}}
					/>
				</div>
				<div
					style={{
						position: "absolute",
						width: 2,
						height: "25%",
						backgroundColor: theme.colors.accent9,
						top: "calc(50% + 20px)",
						left: "50%",
						transform: `translate(-50%, 0)`,
					}}
				/>
				<Box
					paddingHorizontal="md"
					style={{
						position: "absolute",
						width: `min(400px, 100vw)`,

						top: "81%",
						left: "50%",
						transform: `translate(-50%, -50%)`,
					}}
				>
					<ProviderCard />
				</Box>

				{badges.map((badge, index) => (
					<Box
						key={index}
						className={desktopOnlyStyle}
						style={{
							position: "absolute",
							transform: `translate(-50%, -50%)`,
							top: `${badge.top}%`,
							left: `${badge.left}%`,
						}}
					>
						<Badge icon={badge.icon} text={badge.text} />
					</Box>
				))}
			</div>
		</Box>
	);
};
const ProviderCard: React.FC = () => {
	const theme = useTheme();
	return (
		<Box
			gap="xs"
			align="center"
			paddingVertical="xs"
			paddingHorizontal="xs"
			borderRadius={"lg"}
			borderWidth={"lg"}
			borderColor="accent9"
			style={{
				minWidth: 180,
				background: `linear-gradient(${theme.colors.accentA7}, transparent), ${sand.sand1}`,
			}}
		>
			<Box
				align="center"
				width={{ initial: "xxxl", "<md": "xxl" }}
				height={{ initial: "xxxl", "<md": "xxl" }}
				shrink={0}
				borderRadius={"full"}
				borderWidth={"lg"}
				borderColor="sand1"
				style={{ overflow: "hidden" }}
			>
				<img
					src={DrChoudharyImage.toString()}
					style={{ width: "100%", height: "100%" }}
				/>
			</Box>
			<Box column grow shrink gap="xxxs" style={{ minWidth: 0 }}>
				<Text
					size={{
						initial: "md",
						"<md": "sm",
					}}
					weight={"semibold"}
					color="sand12"
				>
					Dr. Choudhary
				</Text>
				<Box column grow shrink>
					<Text
						truncate
						size={{
							initial: "sm",
							"<md": "xs",
						}}
						color="sand11"
						style={{ minWidth: 0, flex: 1 }}
					>
						<Icon
							name="Star"
							color="sand12"
							size={{
								initial: 12,
								"<md": 10,
							}}
							fill="sand12"
							style={{ transform: `translateY(1px)` }}
						/>
						<Text
							size={{
								initial: "sm",
								"<md": "xs",
							}}
							weight={"medium"}
							color="sand12"
						>
							{" "}
							Best match{" "}
						</Text>
						— Has 10 years of documented experience treating patients with
						chronic mu...
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

type SectionProps = {
	children: React.ReactNode;
	title?: string;
	description?: string;
};

const Section: React.FC<SectionProps> = ({ children, title, description }) => {
	return (
		<Box column align="center">
			<Divider />
			<Box
				column
				align="center"
				padding="md"
				style={{ width: `min(880px, 100%)`, overflow: "hidden", zIndex: 1 }}
			>
				<Spacer style={{ height: 36 }} />
				{title && (
					<>
						<SectionTitle>{title}</SectionTitle>
						<Spacer style={{ height: 16 }} />
					</>
				)}
				{description && (
					<>
						<SectionSubtitle>{description}</SectionSubtitle>
						<Spacer style={{ height: 24 }} />
					</>
				)}
				{children}
			</Box>
			<Spacer
				height={{
					"<md": "xl",
					initial: "xxxl",
				}}
			/>
		</Box>
	);
};

const _ProviderDiagram = new URL(
	"../media/ProviderDiagram.png",
	import.meta.url
);

type PolarCoordinateGridProps = {
	initialR: number;
	rStep: number;
	circleCount: number;

	color: string;
};

const PolarCoordinateGrid: React.FC<PolarCoordinateGridProps> = ({
	initialR,
	rStep,
	circleCount,
	color,
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="-350 -185 700 370"
		>
			<defs>
				<radialGradient id="lineFade" r="60%">
					<stop offset="0%" style={{ stopColor: color, stopOpacity: 1 }} />
					<stop offset="100%" style={{ stopColor: color, stopOpacity: 0 }} />
				</radialGradient>
				<radialGradient id="circleFade" r="100%" fr="45%">
					<stop offset="0%" style={{ stopColor: color, stopOpacity: 0 }} />
					<stop offset="100%" style={{ stopColor: color, stopOpacity: 1 }} />
				</radialGradient>
			</defs>

			{range(circleCount).map((i) => {
				return (
					<circle
						key={i}
						cx={0}
						cy={0}
						r={initialR + rStep * i}
						fill="url(#circleFade)"
						stroke={color}
						strokeWidth={1}
						strokeOpacity={(circleCount - i) / circleCount}
					/>
				);
			})}

			<line
				x1="-247.49"
				y1="-247.49"
				x2="247.49"
				y2="247.49"
				stroke="url(#lineFade)"
				strokeWidth="1"
			/>
			<line
				x1="247.49"
				y1="-247.49"
				x2="-247.49"
				y2="247.49"
				stroke="url(#lineFade)"
				strokeWidth="1"
			/>
		</svg>
	);
};

type BadgeProps = {
	icon: IconName;
	text: string;
	style?: React.CSSProperties;
};

const Badge: React.FC<BadgeProps> = ({ icon, text, style }) => {
	return (
		<Box
			inline
			align="center"
			gap="xxs"
			borderWidth={"md"}
			borderColor="sand6"
			background="sand1"
			borderRadius={"md"}
			paddingVertical="xxs"
			paddingHorizontal="xxs"
			style={{ width: "fit-content", ...style }}
		>
			<Icon light name={icon} size={{ initial: 18, "<sm": 16 }} />
			<Text
				truncate
				light
				size={{
					initial: "sm",
					"<sm": "xs",
				}}
				weight={"medium"}
			>
				{text}
			</Text>
		</Box>
	);
};

function user(content: string): UserMessage {
	return {
		type: "user",
		content,
	};
}
function assistant(content: AssistantMessageBlock[]): AssistantMessage {
	return {
		type: "assistant",
		content,
	};
}

function assistantText(text: string): AssistantMessageBlock {
	return {
		type: "text",
		text,
	};
}
function assistantCallInProgress(): AssistantMessageBlock {
	return {
		type: "call-in-progress",
	};
}
function assistantAccessingProviderProfiles(): AssistantMessageBlock {
	return {
		type: "accessing-provider-profiles",
	};
}
function assistantSavingPatientLog(): AssistantMessageBlock {
	return {
		type: "saving-patient-log",
	};
}
function assistantAccessingMedicalRecords(): AssistantMessageBlock {
	return {
		type: "accessing-medical-records",
	};
}
function assistantCallCompleted(): AssistantMessageBlock {
	return {
		type: "call-completed",
	};
}
function assistantAccessingClinicalTrials(): AssistantMessageBlock {
	return {
		type: "accessing-clinical-trials",
	};
}

type UserMessage = {
	type: "user";
	content: string;
};

type AssistantMessageBlock =
	| {
			type: "text";
			text: string;
	  }
	| {
			type: "call-in-progress";
	  }
	| {
			type: "accessing-provider-profiles";
	  }
	| {
			type: "accessing-medical-records";
	  }
	| {
			type: "accessing-clinical-trials";
	  }
	| {
			type: "saving-patient-log";
	  }
	| {
			type: "call-completed";
	  };

type AssistantMessage = {
	type: "assistant";
	content: AssistantMessageBlock[];
};

type Message = UserMessage | AssistantMessage;

type UserConversationItemViewProps = {
	content: string;
};

const UserMessageView: React.FC<UserConversationItemViewProps> = ({
	content,
}) => {
	const theme = useTheme();

	return (
		<Box alignSelf="flex-end" style={{ position: "relative", maxWidth: "75%" }}>
			<MessageTail
				width={16}
				color={theme.colors.accent10}
				style={{ position: "absolute", bottom: 0, right: -3 }}
			/>
			<Box
				inline
				background="accent10"
				style={{
					position: "relative",
					padding: "6px 12px",
					borderRadius: 18,
				}}
			>
				<ThemeProvider mode="dark">
					<Text color="sand12" size={{ initial: "md", "<md": "sm" }}>
						{content}
					</Text>
				</ThemeProvider>
			</Box>
		</Box>
	);
};

type AssistantMessageViewProps = {
	content: AssistantMessageBlock[];
};

const AssistantMessageView: React.FC<AssistantMessageViewProps> = ({
	content,
}) => {
	const theme = useTheme();

	return (
		<Box gap="xs" style={{ alignSelf: "Box-start", maxWidth: "90%" }}>
			<div>
				<CircleLogo
					width={24}
					height={24}
					style={{
						"--background": theme.colors.accent9,
						"--foreground": theme.colors.sand1,
					}}
				/>
			</div>
			<Box column align="flex-start" gap="xs">
				{content.map((block) => {
					switch (block.type) {
						case "text":
							return (
								<Text size={{ initial: "md", "<md": "sm" }}>{block.text}</Text>
							);
						case "call-in-progress":
							return <Badge icon="PhoneCall" text="Call in progress..." />;
						case "call-completed":
							return <Badge icon="PhoneCall" text="Call completed" />;
						case "accessing-clinical-trials":
							return (
								<Box inline gap="xxs" paddingVertical="xxxs" align="center">
									<Icon name="TestTube" size={16} light />
									<Text
										light
										weight={"medium"}
										size={{ initial: "sm", "<md": "xs" }}
									>
										Searched clinical trials
									</Text>
								</Box>
							);
						case "accessing-provider-profiles":
							return (
								<Box inline gap="xxs" paddingVertical="xxxs" align="center">
									<Icon name="FileUser" size={16} light />
									<Text
										light
										weight={"medium"}
										size={{ initial: "sm", "<md": "xs" }}
									>
										Accessed provider profiles
									</Text>
								</Box>
							);
						case "accessing-medical-records":
							return (
								<Box inline gap="xxs" paddingVertical="xxxs" align="center">
									<Icon name="FileUser" size={16} light />
									<Text
										light
										weight={"medium"}
										size={{ initial: "sm", "<md": "xs" }}
									>
										Accessed medical records
									</Text>
								</Box>
							);
						case "saving-patient-log":
							return (
								<Box inline gap="xxs" paddingVertical="xxxs" align="center">
									<Icon name="NotebookPen" size={16} light />
									<Text
										light
										weight={"medium"}
										size={{ initial: "sm", "<md": "xs" }}
									>
										Saved patient log
									</Text>
								</Box>
							);
					}
				})}
			</Box>
		</Box>
	);
};

type AssistantConversationProps = {
	caption: string;
	conversation: Message[];
	conversationBubbleColor: ColorName;
	conversationType?: string | undefined;
};

const AssistantConversation: React.FC<AssistantConversationProps> = ({
	caption,
	conversation,
	conversationType,
}) => {
	return (
		<Box
			column
			grow
			shrink
			basis={0}
			gap={{
				initial: "xxl",
				"<md": "xl",
			}}
			paddingHorizontal="sm"
			style={{ maxWidth: 440 }}
		>
			<Text weight="semibold" light balance align="center">
				{caption}
			</Text>
			{conversationType === "notification" ? (
				<NotificationBox
					title={conversation[0].content as string}
					description={conversation[1].content as string}
				/>
			) : (
				<Box column gap="md" paddingHorizontal="md" paddingVertical="md">
					<Box column gap="md">
						{conversation.map((item, index) => {
							switch (item.type) {
								case "user":
									return <UserMessageView key={index} content={item.content} />;
								case "assistant":
									return (
										<AssistantMessageView key={index} content={item.content} />
									);
							}
						})}
					</Box>
				</Box>
			)}
		</Box>
	);
};

const _PersonalResearchAssistantSection: React.FC = () => (
	<Section
		title="A personal research assistant at your fingertips"
		description="No more sifting through information or waiting for answers — the Saffron Assistant provides instant, clear answers to complex questions."
	>
		<ConversationExamples
			conversation1={[
				user(
					"Are there any new treatments available for my father's chronic musculoskeletal pain?"
				),
				assistant([
					assistantAccessingClinicalTrials(),
					assistantText(
						"A new pain management medication was approved last month that might benefit your father. Would you like information on nearby doctors prescribing it?"
					),
				]),
			]}
			caption1="Search recent information"
			conversation2={[
				user("What do reviews say about Dr. Smith vs. Dr. Choudhary?"),
				assistant([
					assistantAccessingProviderProfiles(),
					assistantText(
						"Dr. Smith has frequent complaints about long wait times, while Dr. Choudhary is praised for excellent bedside manner."
					),
				]),
			]}
			caption2="Compare different providers"
			conversationBubbleColor="amber1"
		/>
	</Section>
);

const ContactUsButton: React.FC = () => {
	const theme = useTheme();

	const className = useStyles({
		"&:hover": {
			background: `linear-gradient(to bottom, ${whiteA.whiteA5}, ${blackA.blackA2}), ${theme.colors.accent9}`,
		},
		background: `linear-gradient(to bottom, ${whiteA.whiteA3}, ${blackA.blackA4}), ${theme.colors.accent9}`,
	});

	return (
		<a
			target="_blank"
			href="mailto:team@saffron.health"
			className={className}
			style={{
				display: "inline-block",
				padding: "5px 12px",
				borderRadius: 6,
				borderWidth: 1,
				borderColor: `rgb(0 0 0 / 0.05)`,
				boxShadow: `
        inset 0 0 0 1px ${whiteA.whiteA2},
        inset 0 2px 1px -2px ${whiteA.whiteA3},
        inset 0 1px 1px ${whiteA.whiteA6},
        inset 0 -1px 1px ${blackA.blackA6},
        inset 0 0 0 1px ${theme.colors.accentA9}`,
			}}
		>
			<ThemeProvider mode="dark">
				<Text
					size={"md"}
					weight={"medium"}
					color="sand12"
					style={{ textShadow: `0 0 1px ${theme.colors.accentA9}` }}
				>
					Contact us
				</Text>
			</ThemeProvider>
		</a>
	);
};

const _SchedulingSection: React.FC = () => (
	<Section
		title="Let Saffon handle the tedious coordination tasks"
		description="The Saffron Assistant handles time-consuming calls and coordination, so you can focus on what's important"
	>
		<ConversationExamples
			conversation1={[
				user("Call my insurance to ask why they denied my last claim."),
				assistant([
					assistantText(
						"Calling Cigna to check on denial status of your claim on October 5, 2024."
					),
					assistantCallInProgress(),
				]),
			]}
			caption1="Check on the status of insurance claims"
			conversation2={[
				user("Schedule an appointment for next week with Dr. Choudhary."),
				assistant([
					assistantText(
						"Calling Dr. Choudhary's office to check availability and make an appointment."
					),
					assistantCallCompleted(),
					assistantText(
						"Your appointment with Dr. Choudhary is scheduled for next Monday at 2:30pm."
					),
				]),
			]}
			caption2="Book appointments"
			conversationBubbleColor="amber1"
		/>
	</Section>
);

// type SingleConversationExamplesProps = {
// 	conversation1: Message[];
// 	caption1: string;
// };
// const SingleConversationExample: React.FC<SingleConversationExamplesProps> = ({
// 	conversation1,
// 	caption1,
// }) => {
// 	return (
// 		<Box
// 			column={{
// 				"<md": true,
// 				initial: false,
// 			}}
// 			justify="center"
// 			gap={{
// 				"<md": "xl",
// 				initial: "lg",
// 			}}
// 			paddingVertical={{
// 				"<md": "lg",
// 				initial: "xl",
// 			}}
// 		>
// 			<AssistantConversation conversation={conversation1} caption={caption1} />
// 			<Divider
// 				vertical={{
// 					"<md": false,
// 					initial: true,
// 				}}
// 				size={64}
// 				className="conversation-divider"
// 			/>
// 			<AssistantConversation conversation={conversation2} caption={caption2} />
// 		</Box>
// 	);
// };

type ConversationExamplesProps = {
	conversation1: Message[];
	caption1: string;
	conversation2: Message[];
	caption2: string;
	conversationBubbleColor: ColorName;
	conversation1Type?: string | undefined;
	conversation2Type?: string | undefined;
};

const ConversationExamples: React.FC<ConversationExamplesProps> = ({
	conversation1,
	caption1,
	conversation2,
	caption2,
	conversationBubbleColor,
	conversation1Type,
	conversation2Type,
}) => {
	return (
		<Box
			column={{
				"<md": true,
				initial: false,
			}}
			justify="center"
			gap={{
				"<md": "xl",
				initial: "lg",
			}}
			paddingVertical={{
				"<md": "lg",
				initial: "xl",
			}}
		>
			<AssistantConversation
				conversation={conversation1}
				caption={caption1}
				conversationBubbleColor={conversationBubbleColor}
				conversationType={conversation1Type}
			/>
			<Divider
				vertical={{
					"<md": false,
					initial: true,
				}}
				size={64}
				className="conversation-divider"
			/>
			<AssistantConversation
				conversation={conversation2}
				caption={caption2}
				conversationBubbleColor={conversationBubbleColor}
				conversationType={conversation2Type}
			/>
		</Box>
	);
};

const Footer: React.FC = () => (
	<footer style={{ margin: 0, position: "relative" }}>
		<Box
			column
			align="center"
			style={{
				paddingTop: {
					"<md": 160,
					initial: 240,
				},
				paddingBottom: {
					"<md": 160,
					initial: 240,
				},
			}}
		>
			<div
				className="footer-gradient"
				style={{
					position: "absolute",
					inset: "-160px 0 0 0",
					zIndex: -1,
				}}
			/>
			<Box column align="center" gap="lg">
				<Text size={"xl"} align="center" balance weight={"semibold"}>
					Improve your patients' health and reclaim your time with Saffron{" "}
					<Text color="accent11" size={"xl"} weight={"semibold"}>
						today
					</Text>
				</Text>
				<ContactUsButton />
			</Box>
		</Box>
	</footer>
);
