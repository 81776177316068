import {
	BellOff,
	BookMarked,
	BookUser,
	BotMessageSquare,
	CalendarDays,
	Check,
	CircleDollarSign,
	CircleHelp,
	CircleUser,
	Clock,
	Construction,
	Database,
	EyeOff,
	FileClock,
	FileText,
	FileUser,
	Frown,
	Gauge,
	Globe,
	Grid2x2X,
	Headset,
	Hospital,
	Info,
	Landmark,
	Lock,
	NotebookPen,
	Percent,
	Phone,
	PhoneCall,
	PointerOff,
	Reply,
	ShieldCheck,
	ShieldOff,
	SmartphoneNfc,
	Snail,
	Star,
	TestTubeDiagonal,
	Thermometer,
	UserPen,
	Workflow,
	X,
} from "lucide-react";
import React from "react";
import { ColorName } from "./ui/theme";
import { useTheme } from "./ui/ThemeProvider";
import { Responsive, useResponsiveStyles } from "./utils/useResponsiveStyles";

const icons = {
	FileUser,
	Hospital,
	BookMarked,
	FileText,
	Capital: Landmark,
	TestTube: TestTubeDiagonal,
	Globe,
	CircleUser,
	Phone,
	Check,
	X,
	PhoneCall,
	Star,
	CircleDollarSign,
	CalendarDays,
	Reply,
	ShieldCheck,
	Gauge,
	UserPen,
	ShieldOff,
	PointerOff,
	Snail,
	Database,
	BotMessageSquare,
	Workflow,
	Percent,
	Grid2x2X,
	Info,
	BookUser,
	Thermometer,
	Headset,
	CircleHelp,
	BellOff,
	SmartphoneNfc,
	Clock,
	Frown,
	Lock,
	EyeOff,
	Construction,
	FileClock,
	NotebookPen,
};

export type IconName = keyof typeof icons;

type IconProps = {
	name: IconName;
	color?: ColorName;
	fill?: ColorName;
	light?: boolean;
	size?: Responsive<number>;
	style?: React.CSSProperties;
};

export const Icon: React.FC<IconProps> = ({
	name,
	size,
	fill: fillName,
	light,
	color: colorName = light ? "sand11" : undefined,
	style,
}) => {
	const theme = useTheme();
	const LucideIcon = icons[name];
	const color = colorName ? theme.colors[colorName] : "currentColor";

	const spanClassName = useResponsiveStyles({
		width: size,
		height: size,
	});

	// For some reason, just defining the fill prop on the LucideIcon component
	// causes it to have a black fill
	const fillStyles = fillName ? { fill: theme.colors[fillName] } : undefined;

	return (
		<span className={spanClassName} style={{ display: "inline-block" }}>
			<LucideIcon
				width="100%"
				height="100%"
				color={color}
				{...fillStyles}
				style={style}
			/>
		</span>
	);
};
