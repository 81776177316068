import {
  amber,
  amberDark,
  blackA,
  blue,
  blueDark,
  grayA,
  green,
  greenDark,
  indigo,
  indigoDark,
  red,
  redDark,
  ruby,
  rubyDark,
  sand,
  sandA,
  sandDark,
  sandDarkA,
  violet,
  violetA,
  violetDark,
  violetDarkA,
} from "@radix-ui/colors";

// There are 12 steps in each scale. Each step was designed for at least one specific use case.
// This table is a simple overview of the most common use case for each step. However, there are many exceptions and caveats to factor in, which are covered in further detail below.

// Step: Use Case
// 1: App background
// 2: Subtle background
// 3: UI element background
// 4: Hovered UI element background
// 5: Active / Selected UI element background
// 6: Subtle borders and separators
// 7: UI element border and focus rings
// 8: Hovered UI element border
// 9: Solid backgrounds
// 10: Hovered solid backgrounds
// 11: Low-contrast text
// 12: High-contrast text

const colors = {
  accent1: violet.violet1,
  accent2: violet.violet2,
  accent3: violet.violet3,
  accent4: violet.violet4,
  accent5: violet.violet5,
  accent6: violet.violet6,
  accent7: violet.violet7,
  accent8: violet.violet8,
  accent9: violet.violet9,
  accent10: violet.violet10,
  accent11: violet.violet11,
  accent12: violet.violet12,

  accentA1: violetA.violetA1,
  accentA2: violetA.violetA2,
  accentA3: violetA.violetA3,
  accentA4: violetA.violetA4,
  accentA5: violetA.violetA5,
  accentA6: violetA.violetA6,
  accentA7: violetA.violetA7,
  accentA8: violetA.violetA8,
  accentA9: violetA.violetA9,
  accentA10: violetA.violetA10,
  accentA11: violetA.violetA11,
  accentA12: violetA.violetA12,

  ...sand,
  ...sandA,
  ...green,
  ...red,
  ...amber,
  ...ruby,
  ...blue,
  ...indigo,

  background: "#fff",
  secondaryBackground: sand.sand1,
  /**
   * Color of text on accent9 background
   */
  accentContrast: "#fff",
};

export type Colors = typeof colors;

const darkColors: Colors = {
  accent1: violetDark.violet1,
  accent2: violetDark.violet2,
  accent3: violetDark.violet3,
  accent4: violetDark.violet4,
  accent5: violetDark.violet5,
  accent6: violetDark.violet6,
  accent7: violetDark.violet7,
  accent8: violetDark.violet8,
  accent9: violetDark.violet9,
  accent10: violetDark.violet10,
  accent11: violetDark.violet11,
  accent12: violetDark.violet12,

  accentA1: violetDarkA.violetA1,
  accentA2: violetDarkA.violetA2,
  accentA3: violetDarkA.violetA3,
  accentA4: violetDarkA.violetA4,
  accentA5: violetDarkA.violetA5,
  accentA6: violetDarkA.violetA6,
  accentA7: violetDarkA.violetA7,
  accentA8: violetDarkA.violetA8,
  accentA9: violetDarkA.violetA9,
  accentA10: violetDarkA.violetA10,
  accentA11: violetDarkA.violetA11,
  accentA12: violetDarkA.violetA12,

  ...sandDark,
  ...sandDarkA,
  ...greenDark,
  ...redDark,
  ...amberDark,
  ...rubyDark,
  ...blueDark,
  ...indigoDark,

  background: sandDark.sand1,
  secondaryBackground: sandDark.sand2,
  accentContrast: "#fff",
};

export type ColorName = keyof Colors;

const spacing = {
  xxxs: 2,
  xxs: 4,
  xs: 6,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 40,
  xxxl: 48,
  xxxxl: 64,
} as const;

export type Spacings = typeof spacing;
export type Spacing = keyof Spacings;

const borderWidths = {
  md: 1.5,
  lg: 2,
} as const;

export type BorderWidths = typeof borderWidths;
export type BorderWidth = keyof BorderWidths;

const borderRadiuses = {
  md: 6,
  lg: 12,
  xl: 16,
  full: 99999,
} as const;

export type BorderRadiuses = typeof borderRadiuses;
export type BorderRadius = keyof BorderRadiuses;

const shadows = {
  inset: `
  inset 0 0 0 1px ${grayA.grayA5},
  inset 0 1.5px 2px 0 ${grayA.grayA2},
  inset 0 1.5px 2px 0 ${blackA.blackA2}`,

  sm: `
  0 0 0 1px ${grayA.grayA3},
  0 0 0 0.5px ${blackA.blackA1},
  0 1px 1px 0 ${grayA.grayA2},
  0 2px 1px -1px ${blackA.blackA1},
  0 1px 3px 0 ${blackA.blackA1}`,

  md: `
  0 0 0 1px ${grayA.grayA3},
  0 8px 40px ${blackA.blackA1},
  0 12px 32px -16px ${grayA.grayA3}`,

  lg: `
  0 0 0 1px ${grayA.grayA3},
  0 12px 60px ${blackA.blackA3},
  0 16px 64px ${grayA.grayA2},
  0 16px 36px -20px ${grayA.grayA7}`,

  xl: `
  0px 3.1px 4.9px rgba(0, 0, 0, 0.017),
  0px 7.4px 9.9px rgba(0, 0, 0, 0.022),
  0px 14px 15.7px rgba(0, 0, 0, 0.027),
  0px 25px 23.6px rgba(0, 0, 0, 0.032),
  0px 46.8px 37.6px rgba(0, 0, 0, 0.041),
  0px 112px 80px rgba(0, 0, 0, 0.07)
`,

  debug: `0 0 10px 10px red`,
} as const;

export type Shadows = typeof shadows;
export type Shadow = keyof Shadows;

export const textWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

export type TextWeights = typeof textWeights;
export type TextWeight = keyof TextWeights;

const fontStyles = {
  xxxl: {
    fontSize: 28,
    lineHeight: "36px",
    letterSpacing: "-0.0075em",
  },
  xxl: {
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: "-0.00625em",
  },
  xl: {
    fontSize: 20,
    lineHeight: "28px",
    letterSpacing: "-0.005em",
  },
  lg: {
    fontSize: 18,
    lineHeight: "26px",
    letterSpacing: "-0.0025em",
  },
  md: {
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0",
  },
  sm: {
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: "0",
  },
  xs: {
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.0025em",
  },
} satisfies Record<
  string,
  {
    fontSize: number;
    lineHeight: string;
    letterSpacing: string;
  }
>;

export type FontStyles = typeof fontStyles;
export type TextSize = keyof FontStyles;

export const fontFamilies = {
  Newsreader: "Newsreader",
};

export type FontFamilies = typeof fontFamilies;
export type FontFamily = keyof FontFamilies;

export type Theme = {
  colors: Colors;
  spacing: Spacings;
  borderWidths: BorderWidths;
  borderRadiuses: BorderRadiuses;
  fontStyles: FontStyles;
  textWeights: TextWeights;
  fontFamilies: FontFamilies;
  shadows: Shadows;
};

export const lightTheme: Theme = {
  colors,
  spacing,
  borderWidths,
  borderRadiuses,
  fontStyles,
  textWeights,
  fontFamilies,
  shadows,
};

export const darkTheme: Theme = {
  colors: darkColors,
  spacing,
  borderWidths,
  borderRadiuses,
  fontStyles,
  textWeights,
  fontFamilies,
  shadows,
};
