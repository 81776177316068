import { useStyles } from "purse-styles";
import React, { useState } from "react";
import { Button, Dialog, DialogTrigger, Popover } from "react-aria-components";
import { Icon } from "./Icon";
import { Box, Spacer } from "./ui/Box";
import { Text } from "./ui/Text";
import { useTheme } from "./ui/ThemeProvider";

interface TooltipProps {
	content: string;
	source: string;
	url: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, source }) => {
	const theme = useTheme();
	const [isOpen, onOpenChange] = useState<boolean>(false);

	const focusStyle = useStyles({
		["&:focus-visible[data-focus-visible]"]: {
			boxShadow: `0 0 0 1.5px ${theme.colors.accent9}`,
		},

		boxShadow: "none",
		transition: "box-shadow 0.12s ease-in-out",
		outline: "none",
	});

	return (
		<DialogTrigger onOpenChange={onOpenChange} isOpen={isOpen}>
			<Button
				className={focusStyle}
				style={{
					cursor: "pointer",
					width: 16,
					height: 16,
					borderRadius: "100%",
					padding: 1,
					transform: `translateY(2px)`,
				}}
			>
				<Icon
					name={"Info"}
					light
					size={14}
					color={isOpen ? "sand2" : "sand11"}
					fill={isOpen ? "accent9" : undefined}
				/>
			</Button>
			<Popover>
				<Dialog className={focusStyle} style={{ padding: 2, borderRadius: 4 }}>
					<Box
						column
						background="sand4"
						paddingHorizontal="sm"
						paddingVertical="sm"
						borderRadius="md"
						shadow="md"
						style={{ width: "clamp(250px, 30vw, 400px)" }}
					>
						<Text size="sm" color="sand12">
							{content}
						</Text>
						<Spacer height="xs" />
						<span>
							<a
								href={"https://doi.org/10.26419/ppi.00103.001"}
								target="_blank"
								rel="noopener noreferrer"
							>
								<Text size="xs" color="sand10" italic>
									{source}
								</Text>
							</a>
						</span>
					</Box>
				</Dialog>
			</Popover>
		</DialogTrigger>
	);
};
