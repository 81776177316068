import React from "react";
import {
	mapResponsiveValue,
	Responsive,
	ResponsiveStyles,
	useResponsiveStyles,
} from "../utils/useResponsiveStyles";
import { BorderRadius, BorderWidth, ColorName, Shadow, Spacing } from "./theme";
import { useTheme } from "./ThemeProvider";

type FlexStyle = React.CSSProperties;

export type BoxProps = {
	column?: Responsive<boolean>;
	gap?: Responsive<Spacing>;
	align?: Responsive<FlexStyle["alignItems"]>;
	justify?: Responsive<FlexStyle["justifyContent"]>;
	wrap?: Responsive<FlexStyle["flexWrap"] | true>;
	inline?: Responsive<boolean>;

	flex?: Responsive<FlexStyle["flex"] | true>;
	basis?: FlexStyle["flexBasis"];
	grow?: FlexStyle["flexGrow"] | true;
	shrink?: FlexStyle["flexShrink"] | true;
	alignSelf?: FlexStyle["alignSelf"];

	shadow?: Responsive<Shadow | true>;

	style?: ResponsiveStyles;
	className?: string;

	background?: ColorName;

	width?: Responsive<Spacing>;
	height?: Responsive<Spacing>;

	borderRadius?: BorderRadius;

	borderWidth?: Responsive<BorderWidth | undefined>;
	borderTopWidth?: Responsive<BorderWidth | undefined>;
	borderLeftWidth?: Responsive<BorderWidth | undefined>;
	borderRightWidth?: Responsive<BorderWidth | undefined>;
	borderBottomWidth?: Responsive<BorderWidth | undefined>;
	borderColor?: ColorName;

	padding?: Responsive<Spacing | undefined>;
	paddingVertical?: Responsive<Spacing | undefined>;
	paddingHorizontal?: Responsive<Spacing | undefined>;

	children?: React.ReactNode;
};

export const Box: React.FC<BoxProps> = ({
	flex,
	basis,
	grow,
	shrink,
	inline,

	column,
	gap,
	align,
	justify,
	wrap,
	alignSelf,

	shadow,

	padding,
	paddingVertical,
	paddingHorizontal,

	borderWidth,
	borderTopWidth,
	borderLeftWidth,
	borderRightWidth,
	borderBottomWidth,
	borderColor = "sand6",

	borderRadius,

	width,
	height,

	background,

	style,
	className = "",

	children,
}) => {
	const theme = useTheme();

	const hasBorder =
		borderWidth ||
		borderTopWidth ||
		borderLeftWidth ||
		borderRightWidth ||
		borderBottomWidth;

	const stylesClassName = useResponsiveStyles({
		flex: mapResponsiveValue(flex, (value) =>
			value === true ? `1 1 auto` : value
		),
		flexDirection: mapResponsiveValue(column, (value) =>
			value ? "column" : "row"
		),
		gap: mapResponsiveValue(gap, (gap) =>
			gap ? theme.spacing[gap] : undefined
		),
		alignItems: align,
		justifyContent: justify,
		flexWrap: mapResponsiveValue(wrap, (value) =>
			value === true ? "wrap" : value
		),
		display: mapResponsiveValue(inline, (value) =>
			value ? "inline-flex" : "flex"
		),
		// React is weird if these props are undefined, so we need to conditionally add
		// them.
		// For border-width, set to 0 if a different border prop is provided
		// because otherwise, all the other border widths will get set to
		// browser default
		...(hasBorder && borderWidth
			? mapResponsiveValue(borderWidth, (borderWidth) => ({
					borderWidth: borderWidth ? theme.borderWidths[borderWidth] : 0,
			  }))
			: { borderWidth: 0 }),
		...(hasBorder && borderTopWidth
			? mapResponsiveValue(borderTopWidth, (borderTopWidth) => ({
					borderTopWidth: borderTopWidth
						? theme.borderWidths[borderTopWidth]
						: 0,
			  }))
			: {}),
		...(hasBorder && borderLeftWidth
			? mapResponsiveValue(borderLeftWidth, (borderLeftWidth) => ({
					borderLeftWidth: borderLeftWidth
						? theme.borderWidths[borderLeftWidth]
						: 0,
			  }))
			: {}),
		...(hasBorder && borderRightWidth
			? mapResponsiveValue(borderRightWidth, (borderRightWidth) => ({
					borderRightWidth: borderRightWidth
						? theme.borderWidths[borderRightWidth]
						: 0,
			  }))
			: {}),
		...(hasBorder && borderBottomWidth
			? mapResponsiveValue(borderBottomWidth, (borderBottomWidth) => ({
					borderBottomWidth: borderBottomWidth
						? theme.borderWidths[borderBottomWidth]
						: 0,
			  }))
			: {}),
		borderStyle: hasBorder ? "solid" : undefined,

		boxShadow: mapResponsiveValue(shadow, (shadow) =>
			shadow === true
				? theme.shadows.md
				: shadow
				? theme.shadows[shadow]
				: undefined
		),

		flexBasis: basis,
		flexGrow: grow === true ? 1 : grow,
		flexShrink: shrink === true ? 1 : shrink,

		alignSelf,

		backgroundColor: background ? theme.colors[background] : undefined,

		padding: mapResponsiveValue(padding, (padding) =>
			padding ? theme.spacing[padding] : undefined
		),
		paddingTop: mapResponsiveValue(paddingVertical, (paddingVertical) =>
			paddingVertical ? theme.spacing[paddingVertical] : undefined
		),
		paddingBottom: mapResponsiveValue(paddingVertical, (paddingVertical) =>
			paddingVertical ? theme.spacing[paddingVertical] : undefined
		),
		paddingLeft: mapResponsiveValue(paddingHorizontal, (paddingHorizontal) =>
			paddingHorizontal ? theme.spacing[paddingHorizontal] : undefined
		),
		paddingRight: mapResponsiveValue(paddingHorizontal, (paddingHorizontal) =>
			paddingHorizontal ? theme.spacing[paddingHorizontal] : undefined
		),

		width: mapResponsiveValue(width, (width) =>
			width ? theme.spacing[width] : undefined
		),
		height: mapResponsiveValue(height, (height) =>
			height ? theme.spacing[height] : undefined
		),

		borderRadius: mapResponsiveValue(borderRadius, (borderRadius) =>
			borderRadius ? theme.borderRadiuses[borderRadius] : undefined
		),

		borderColor: theme.colors[borderColor],
	});

	const otherStyles = useResponsiveStyles(style);

	return (
		<div className={className + " " + stylesClassName + " " + otherStyles}>
			{children}
		</div>
	);
};

type SpacerProps = {
	flex?: FlexStyle["flex"] | true;
	basis?: FlexStyle["flexBasis"];
	grow?: FlexStyle["flexGrow"] | true;
	shrink?: FlexStyle["flexShrink"] | true;

	height?: Responsive<Spacing>;
	width?: Responsive<Spacing>;
	style?: ResponsiveStyles;
};

export const Spacer: React.FC<SpacerProps> = ({
	flex,
	basis,
	grow,
	shrink,

	height,
	width,
	style,
}) => {
	const theme = useTheme();
	const className = useResponsiveStyles({
		flex: mapResponsiveValue(flex, (value) =>
			value === true ? `1 1 auto` : value
		),
		height: mapResponsiveValue(height, (value) =>
			value ? theme.spacing[value] : undefined
		),
		width: mapResponsiveValue(width, (value) =>
			value ? theme.spacing[value] : undefined
		),
		flexBasis: basis,
		flexGrow: mapResponsiveValue(grow, (value) => (value === true ? 1 : value)),
		flexShrink: mapResponsiveValue(shrink, (value) =>
			value === true ? 1 : value
		),
		...style,
	});

	return <div className={className} />;
};
